import React from "react"
import SEO from '../../components/seo'

import Osirase from '../../layouts/osirase'

export default () => (


  <Osirase>

  	<SEO title="確認しました"/>

  	<p>教材に興味を持ってくれてありがとうございます。</p>


	<p>５分ほどで確認のメールが届きますので、<br/>
			迷惑メールフォルダも含めてチェックしてください。</p>

		<p><strong>「迷惑メールフォルダに入っていた」というご報告をよくいただきます。</strong>	</p>

	<p>もし、３０分以上経っても届いていなければ、メールマガジンに「実践編の案内希望」とタイトルをつけて返信してください。本文は空で構いません</p>

  </Osirase>
)
